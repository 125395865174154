import React from "react";
import LocationBanner from "components/locationBanner/index";
import EmailerLocation from "components/LocationReusableComp/emailer";
import TranslateAgency from "components/homepage/translateAgency";
import PopularGrid from "components/LocationReusableComp/PopularGrid";
import { DemandInChicago, LanguagesInLondon } from "./config";
import LookingTranslator from "components/LocationReusableComp/looking";
import ServicesGrid from "components/homepage/servicesGrid";
import { Link } from "gatsby";
import Popular from "components/LocationReusableComp/popular";
import WhatChooseUs from "components/homepage/whyChooseUs";
import Reviews from "components/homepage/reviews";
import TranslationServices from "components/homepage/translationServices";
import GetInTouch from "components/getInTouch";

function index() {
  return (
    <>
      <LocationBanner
        backgroundCol="bg-london-banner"
        title="London Translation Services"
        content="Have you been searching for translation services in London, to no avail? Good thing Tomedes is here. Tomedes Translation Agency in London provides translation, localization, and interpretation services in over 120 languages and 950+ language pairs. Tomedes equips you with language solutions in addition to agile delivery, a 1-year guarantee, and 24/7 customer support. So, get in touch today."
        formOrangeHead="Get in touch "
        formWhiteHead="with our translation company"
      />
      <EmailerLocation />
      <TranslateAgency
        head="Your Trusted Translation Agency in "
        colorHead="London"
        content="Tomedes is not just one of your average translation agencies in London: rather, we help businesses and individuals broaden their horizons and gain access to London’s 863,000 limited English-speaking population. We do this through client-centered translation services, as shown by our 97% client satisfaction rate. Tomedes is rated 4.8 out of 5 stars, and our dedicated customer support is available 24/7 for you. Now it’s your turn to put your trust in Tomedes."
      />
      <PopularGrid
        backgroundCol="bg-midBlue"
        titleColor="text-orange"
        bottomBorderText
        languageCount="Our Interpretation and Translation Services in"
        location="London"
        data={LanguagesInLondon}
        linkName2
        bottomBorderText
        linkName=" "
      />
      <LookingTranslator
        backgroundCol="bg-darkBlue"
        picture="https://tomedes.gumlet.io/frontend/images/location_new/cta_london.webp"
        translationAt="for the right translation agency in London?"
        detail="Are you tired of trying to find the right translation agency in London? Never fear, Tomedes is here. We provide native-language translators who are also industry experts. Tomedes is devoted to providing accurate, quality, and top-of-the-line translation services. Give us a call, we respond in 30 minutes."
        countryName="UNITED KINGDOM"
        countryCode="UK"
        location="London"
        address="7 Bell Yard, London,<br />
        WC2A 2 JR United Kingdom"
        contactNumber="+44 (0)16 1509 6140"
      />
      <ServicesGrid
        backgroundCol="bg-midBlue"
        heading="A London Translation Company Made for Every Industry"
        details="Tomedes is trusted by 95,000+ clients globally, which includes Google, Android, and Fortune 500 companies. We can provide translation services made for every industry in London. Join our growing network of clients, in these industries:"
        contactUs={
          <Link
            to="/contactus.php"
            className="en-btn bg-orange text-white rounded-3xl uppercase py-2 px-8 mt-6 inline-block  mlap:mb-10"
          >
            contact us
          </Link>
        }
      />
      <Popular
        backgroundCol="bg-darkBlue"
        footerTitle="In-demand Languages in London"
        footerDetails="Did you know that there are over 120 languages and 950+ language pairs in the language list of Tomedes?"
        bottomBorderText
        languageCount="London Translation Services in 120 languages and 950+ Language Pairs"
        details="Out of over 120 languages and 950+ language pairs, the following languages are the most popular in London:"
        bottomHeading="In-demand Languages in London"
        location=""
        data={DemandInChicago}
      />
      <WhatChooseUs backgroundCol="bg-midBlue" />
      <Reviews backgroundCol="bg-darkBlue" />
      <TranslationServices backgroundCol="bg-midBlue" />
      <GetInTouch />
    </>
  );
}

export default index;

import SEO from "components/seo";
import Layout from "components/layout";
import React from "react";
import London from "components/locationIndexPages/london/index";

function index() {
  const title = "London Translation Services Agency- Tomedes";
  const description =
    "Looking for London's trusted translation agency? Tomedes is here. Translation services in over 120 languages and 950+ language pairs. 1-Year Guarantee | Fast Delivery | 24/7 Availability";
  const keywords = "Translation Services in London";
  return (
    <Layout>
      <SEO
        title={title}
        description={description}
        keywords={keywords}
        slug="/locations/london-translation-services"
      />
      <London />
    </Layout>
  );
}

export default index;

import React from "react";
import { Link } from "gatsby";
export default function PopularGrid({
  languageCount,
  details,
  location,
  data,
  titleColor,
  backgroundCol,
  bottomHeading,
  bottomDetails,
  bottomBorderText,
  linkName2,
  linkName,
}) {
  return (
    <>
      <section
        className={`${backgroundCol ? backgroundCol : "bg-midBlue"} py-20`}
      >
        <div className="max-w-6xl mx-auto ">
          <div>
            <h2 className="text-center md:text-left text-gray w-8/12 xl:pl-0 sm:pl-10 md:pr-36 mr-auto text-4xl font-semibold leading-normal pt-2 mb-3 slap:w-full mlg:w-9/12 slap:px-6 font-secondary ">
              {languageCount}{" "}
              <span className={`${titleColor ? titleColor : "text-orange"}`}>
                {location}
              </span>
            </h2>
            {details ? (
              <p className="text-center text-darkMist text-base font-secondary md:w-10/12 mtab:px-6 mx-auto pt-5">
                {details}
              </p>
            ) : null}

            <div className="border-t border-gray my-8  border-opacity-25 mmd:mx-20 mlg:mx-20 mtab:mx-6  "></div>
            {bottomHeading ? (
              <h3 className="text-2xl text-center font-secondary text-gray mt-10 mb-20 font-semibold mx-6">
                {bottomHeading} {location}
              </h3>
            ) : null}

            {bottomDetails ? (
              <p className="text-center text-darkMist text-base font-opensans md:w-10/12 mtab:px-6 mx-auto pt-5 px-2 md:px-24 mb-20 leading-relaxed">
                {bottomDetails}
              </p>
            ) : null}
            <div className=" mt-3 lap:grid grid-cols-1 lap:gap-4">
              {data
                ? data.map(
                    (
                      {
                        heading,
                        detail,
                        link,
                        secondHeading,
                        extraHead,
                        extraLink,
                        pad,
                        location,
                      },
                      index
                    ) => (
                      <>
                        <div
                          className="lap:px-20 pb-3 lap:pb-16 slap:px-6 grid grid-cols-1 lg:grid-cols-2"
                          key={index}
                        >
                          <h3
                            className={`text-orange text-2xl font-secondary font-bold mb-3`}
                          >
                            {heading}{" "}
                            {secondHeading ? (
                              <span className="text-white">
                                {secondHeading}
                              </span>
                            ) : null}
                          </h3>
                          <div>
                            {pad ? (
                              pad
                            ) : (
                              <p
                                className="text-darkMist font-secondary text-base leading-7 mb-3 lap:pr-4"
                                dangerouslySetInnerHTML={{ __html: detail }}
                              ></p>
                            )}

                            {extraHead ? (
                              <div>
                                <h4 className="text-[20px] font-bold text-orange font-secondary">
                                  Legal
                                </h4>
                                <p className="text-[#bebebe] font-opensans  py-4">
                                  For legal documents, our legal translators are
                                  the ones behind {location ? location : ""}{" "}
                                  legal translation services. You don’t want
                                  your legal documents to be translated by none
                                  other than legal translators, who
                                  conscientiously know the terminologies from
                                  all types of law: civic, corporate,
                                  environmental, criminal, and more.
                                </p>
                                <Link
                                  to="/legal-translation.php"
                                  className="text-gray underline  leading-7 learnmore hover:text-orange text-lg font-opensans"
                                >
                                  GET STARTED
                                </Link>
                                <h4 className="text-[20px] pt-16 font-bold text-orange font-secondary">
                                  Medical
                                </h4>
                                <p className="text-[#bebebe] font-opensans  py-8">
                                  For all your {location ? location : ""}{" "}
                                  medical translation services, Tomedes provides
                                  medical translators for all your in-person and
                                  telehealth needs. Our medical interpreters can
                                  assist you in doctor’s visits and more, while
                                  our medical document translator can translate
                                  all your necessary patient charts, lab
                                  reports, and findings.
                                </p>
                                <Link
                                  to="/medical-translation.php"
                                  className="text-gray underline  leading-7 learnmore hover:text-orange text-lg font-opensans"
                                >
                                  GET STARTED
                                </Link>
                                <h4 className="text-[20px] pt-16 font-bold text-orange font-secondary">
                                  Technical
                                </h4>
                                <p className="text-[#bebebe] font-opensans  py-8">
                                  Tomedes’ {location ? location : ""} technical
                                  translation services for fields such as data
                                  science, information technology, engineering,
                                  and the sciences. User manuals, product
                                  manuals, and more can be translated into the
                                  over 120 languages available at Tomedes. This
                                  includes glossaries of specific terminologies
                                  as well.
                                </p>
                                <Link
                                  to="/technical-translation.php"
                                  className="text-gray underline  leading-7 learnmore hover:text-orange text-lg font-opensans"
                                >
                                  GET STARTED
                                </Link>
                                <h4 className="text-[20px] font-bold pt-16 text-orange font-secondary">
                                  Financial
                                </h4>
                                <p className="text-[#bebebe] font-opensans  py-8">
                                  Financial documents are a necessary part of a
                                  business’ daily operations or an individual’s
                                  everyday life. At Tomedes, our{" "}
                                  {location ? location : ""} financial
                                  translation services make sure your financial
                                  documents are translated by industry experts,
                                  who also have the native-language skills to
                                  boot. We translate documents such as tax
                                  returns, income statements, and balance
                                  sheets.
                                </p>
                                <Link
                                  to="/financial-translation.php"
                                  className="text-gray underline  leading-7 learnmore hover:text-orange text-lg font-opensans"
                                >
                                  GET STARTED
                                </Link>
                              </div>
                            ) : null}

                            {extraLink ? (
                              extraLink
                            ) : (
                              <Link
                                to={link}
                                className="text-gray underline  leading-7 learnmore hover:text-orange text-lg font-opensans"
                              >
                                GET STARTED
                              </Link>
                            )}
                          </div>
                        </div>
                        <div className="border-t border-gray my-8  border-opacity-25 mmd:mx-20 mlg:mx-20 mtab:mx-6 "></div>
                      </>
                    )
                  )
                : null}
            </div>
            {bottomBorderText ? (
              <div className="div grid grid-cols-1 lg:grid-cols-2">
                <h3 className="md:text-3xl text-2xl pl-2 md:pl-12 text-center md:text-left font-secondary text-gray mt-10 mb-2 pr-4 md:pr-20 font-semibold mx-6">
                  Didn't see the service you were looking for?
                </h3>
                <div>
                  <p className="text-center md:text-left text-darkMist text-base font-opensans mb-12 md:w-10/12 mtab:px-6 pl-5 md:pl-0 pt-5">
                    If you didn't see the service you were looking for, we offer
                    a host of translation services for the London area. This
                    includes website, app, and game localisation, marketing
                    translation, and more. Just reach out to us to learn more.
                  </p>
                  {linkName ? (
                    linkName
                  ) : (
                    <Link
                      to="/services/translation/"
                      className="text-gray underline pl-5 learnmore leading-7 hover:text-orange text-lg text-left block mx-auto mb-7 font-opensans"
                    >
                      GET STARTED
                    </Link>
                  )}

                  {linkName2 ? (
                    <Link
                      to="/contactus.php"
                      className="text-gray underline  leading-7 learnmore hover:text-orange text-lg font-opensans md:pl-0 pl-[9rem]"
                    >
                      CONTACT US
                    </Link>
                  ) : null}

                  {bottomBorderText}
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </section>
    </>
  );
}
